var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { Tokenizer as DefaultTokenizer } from 'marked';
var Tokenizer = /** @class */ (function (_super) {
    __extends(Tokenizer, _super);
    function Tokenizer(src, options) {
        var _this = _super.call(this, options) || this;
        _this.src = src;
        _this.hrOffsets = [];
        return _this;
    }
    Tokenizer.prototype.getHrOffsets = function () {
        return __spreadArrays(this.hrOffsets);
    };
    Tokenizer.prototype.hr = function (src) {
        var result = _super.prototype.hr.call(this, src);
        if (result) {
            var offset = this.src.length - src.length;
            this.hrOffsets.push(offset);
        }
        return result;
    };
    return Tokenizer;
}(DefaultTokenizer));
export { Tokenizer };
