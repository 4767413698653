function searchParamsOfHash(hash) {
    return new URLSearchParams(hash.replace('#', '?'));
}
function hashOfSearchParams(searchParams) {
    var hash = searchParams.toString();
    return hash;
}
export function withSetHashParam(param, value, hash) {
    var searchParams = searchParamsOfHash(hash);
    searchParams.set(param, value);
    return hashOfSearchParams(searchParams);
}
export function setHashParam(param, value) {
    location.hash = withSetHashParam(param, value, location.hash);
}
export function getHashParamFrom(param, hash) {
    var searchParams = searchParamsOfHash(hash);
    return searchParams.get(param);
}
export function getHashParam(param) {
    return getHashParamFrom(param, location.hash);
}
export function withRemovedHashParam(param, hash) {
    var searchParams = searchParamsOfHash(hash);
    searchParams.delete(param);
    return hashOfSearchParams(searchParams);
}
export function removeHashParam(param) {
    location.hash = withRemovedHashParam(param, location.hash);
}
