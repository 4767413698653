var key = 'savedSlides';
export function clearSlides() {
    localStorage.removeItem(key);
}
export function loadSlides() {
    return JSON.parse(localStorage.getItem(key) || '{}');
}
export function saveSlides(title, url) {
    var savedSlides = loadSlides();
    savedSlides[url] = {
        title: title,
        lastAccessed: new Date().toISOString(),
    };
    localStorage.setItem(key, JSON.stringify(savedSlides));
}
