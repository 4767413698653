import { getElementById } from './get-element-by-id';
var exampleUrls = {
    'Github Gist': 'https://gist.githubusercontent.com/schubev/52bdafbc25594ba93a55227fe2a5e7d7/raw/2ac17da408a41dc3aa9b5a5508118122d951046a/slides-test.md',
    'Data URL': 'data:text/plain;charset=utf-8,%23%20Data%20URLs%20and%20how%20to%20use%20them%0D%0A%0D%0A---%0D%0A%0D%0A-%20Data%20URLs%20don%E2%80%99t%20point%20to%20anything%0D%0A-%20They%20actually%20contain%20the%20data%20they%20%E2%80%9Cpoint%E2%80%9D%20to%0D%0A-%20They%20mean%20you%20don%E2%80%99t%20need%20to%20host%20your%20thing%0D%0A%0D%0A---%0D%0A%0D%0A%2AJust%20know%20you%E2%80%99re%20limited%20in%20how%20much%20data%20you%20can%20cram%20in.%2A%20%28something%20like%202KB%29%0D%0A%0D%0A---%0D%0A%0D%0AOtherwise%20everything%20still%20works.%0D%0A%0D%0AYou%20can%20for%20example%20use%20%5Bthis%20generator%5D%28https%3A%2F%2Fdopiaza.org%2Ftools%2Fdatauri%2Findex.php%29%20to%20make%20your%20URLs.',
};
export function fillExamples() {
    var examples = getElementById(HTMLUListElement, 'welcome-examples');
    var welcomeUrlInput = getElementById(HTMLInputElement, 'welcome-url-input');
    var _loop_1 = function (name_1) {
        var li = document.createElement('li');
        var a = document.createElement('a');
        a.innerText = name_1;
        a.setAttribute('href', 'javascript:void(0)');
        a.addEventListener('click', function () {
            welcomeUrlInput.value = exampleUrls[name_1];
        });
        li.appendChild(a);
        examples.appendChild(li);
    };
    for (var name_1 in exampleUrls) {
        _loop_1(name_1);
    }
}
