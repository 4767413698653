import { getElementById } from './get-element-by-id';
import { setTheme, setThemeByName } from './themes';
import { getHashParam } from './hash-param';
export function bindSettings() {
    var cycleTheme = getElementById(HTMLButtonElement, 'settings-theme');
    var urlTheme = getHashParam('theme');
    var currentTheme;
    if (urlTheme !== null) {
        currentTheme = setThemeByName(urlTheme);
    }
    else {
        currentTheme = setTheme(0);
    }
    cycleTheme.addEventListener('click', function () {
        currentTheme = setTheme(currentTheme + 1);
    });
}
