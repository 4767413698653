import { getElementById } from './get-element-by-id';
import { getHashParam, setHashParam } from './hash-param';
import { loadSlides } from './saved-slides';
import { bindControls } from './controls';
import { makeDomFromMarkdown } from './dom-of-markdown';
export function bindWelcomeForm() {
    var welcome = getElementById(HTMLFormElement, 'welcome');
    var welcomeUrlInput = getElementById(HTMLInputElement, 'welcome-url-input');
    var welcomeUrlSubmit = getElementById(HTMLButtonElement, 'welcome-submit');
    var urlUrl = getHashParam('url');
    welcome.addEventListener('submit', function (event) {
        var url = welcomeUrlInput.value;
        load(url);
        event.preventDefault();
    });
    showSavedSlides();
    if (urlUrl) {
        load(urlUrl);
    }
    function load(url) {
        welcomeUrlSubmit.setAttribute('disabled', 'true');
        fetch(url)
            .then(function (response) {
            if (response.status === 200)
                return response.text();
            else {
                console.error(response);
                throw Error('Did not respond with 200');
            }
        })
            .then(function (text) {
            start(text, url);
        })
            .catch(console.error)
            .then(function () {
            welcomeUrlSubmit.removeAttribute('disabled');
        });
    }
    function start(text, url) {
        var settings = getElementById(HTMLDivElement, 'settings');
        settings.setAttribute('hidden', 'true');
        welcome.remove();
        setHashParam('url', url);
        makeDomFromMarkdown(text, url);
        bindControls();
    }
    function showSavedSlides() {
        var previouslyAccessed = getElementById(HTMLDivElement, 'previously-accessed');
        var ol = previouslyAccessed.getElementsByTagName('ol')[0];
        var slides = loadSlides();
        var urls = Object.keys(slides);
        if (urls.length > 0)
            previouslyAccessed.removeAttribute('hidden');
        urls
            .sort(function (a, b) { return Number(slides[a].lastAccessed < slides[b].lastAccessed); })
            .forEach(function (url) {
            var li = document.createElement('li');
            var a = document.createElement('a');
            a.setAttribute('href', 'javascript:void(0)');
            a.innerText = slides[url].title;
            a.addEventListener('click', function () {
                load(url);
            });
            li.appendChild(a);
            ol.appendChild(li);
        });
    }
}
