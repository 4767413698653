import { setHashParam } from './hash-param';
export var themes = [
    { name: 'light', kind: 'light' },
    { name: 'antique', kind: 'light' },
    { name: 'dark', kind: 'dark' },
    { name: 'navy', kind: 'dark' },
];
export function setThemeByName(themeName) {
    var themeIndex = themes.findIndex(function (_a) {
        var name = _a.name;
        return name === themeName;
    });
    return setTheme(themeIndex === -1 ? 0 : themeIndex);
}
function positiveModulo(value, modulo) {
    return ((value % modulo) + modulo) % modulo;
}
export function setTheme(themeIndex) {
    var currentTheme = positiveModulo(themeIndex, themes.length);
    var theme = themes[currentTheme];
    setHashParam('theme', theme.name);
    Array.from(document.body.classList)
        .filter(function (className) { return className.startsWith('theme'); })
        .forEach(function (className) { return document.body.classList.remove(className); });
    document.body.classList.add("theme-kind-" + theme.kind, "theme-" + theme.name);
    return currentTheme;
}
