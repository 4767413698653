var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import * as marked from 'marked';
import { Tokenizer } from './tokenizer';
import { saveSlides } from './saved-slides';
function getTitle(slidesDom) {
    function fromFirstOf(elements) {
        var element = elements[0];
        if (!(element instanceof HTMLElement))
            return null;
        if (element !== undefined)
            return element.innerText.trim();
        return null;
    }
    return (fromFirstOf(slidesDom.getElementsByTagName('H1')) ||
        fromFirstOf(slidesDom.getElementsByTagName('H2')) ||
        fromFirstOf(slidesDom.getElementsByClassName('slide')) ||
        'Untitled');
}
function hasNoText(slideContainer) {
    var children = slideContainer.children;
    if (children.length === 1)
        return true;
    else
        for (var i = 0; i < children.length; i++) {
            var child = children[i];
            if (['P', 'UL', 'OL', 'BLOCKQUOTE'].indexOf(child.tagName) !== -1 &&
                child.innerText.trim() !== '')
                return false;
        }
    return true;
}
export function parseMarkdown(markdown) {
    var slides = document.createElement('div');
    var tokenizer = new Tokenizer(markdown);
    slides.innerHTML = marked(markdown, { tokenizer: tokenizer });
    var hrOffsets = tokenizer.getHrOffsets();
    var children = [];
    for (var i = 0; i < slides.children.length; i++)
        children.push(slides.children[i]);
    children
        .reduce(function reducer(acc, cur) {
        cur.remove();
        var currentSlide = acc[0], previousSlides = acc.slice(1);
        if (cur.tagName === 'HR') {
            var startOffset = hrOffsets.shift();
            if (startOffset == null)
                throw Error('unknown offset for hr');
            return __spreadArrays([
                { startOffset: startOffset, elements: [] },
                currentSlide
            ], previousSlides);
        }
        else {
            return __spreadArrays([
                __assign(__assign({}, currentSlide), { elements: __spreadArrays(currentSlide.elements, [cur]) })
            ], previousSlides);
        }
    }, [{ startOffset: 0, elements: [] }])
        .reverse()
        .map(function (_a) {
        var startOffset = _a.startOffset, elements = _a.elements;
        var container = document.createElement('div');
        container.setAttribute('class', 'slide');
        container.dataset.startOffset = String(startOffset);
        elements.forEach(function (element) {
            container.appendChild(element);
        });
        return container;
    })
        .forEach(function (slideContainer) {
        if (hasNoText(slideContainer))
            slideContainer.classList.add('notext');
        slides.appendChild(slideContainer);
    });
    var title = getTitle(slides);
    return { slides: slides, title: title };
}
export function makeDomFromMarkdown(markdown, url) {
    var _a = parseMarkdown(markdown), slides = _a.slides, title = _a.title;
    slides.setAttribute('id', 'slides');
    saveSlides(title, url);
    document.body.prepend(slides);
}
