import { fillExamples } from './examples';
import { bindSettings } from './settings';
import { bindWelcomeForm } from './welcome';
import { getElementById } from './get-element-by-id';
import { Editor } from './editor';
import './style.sass';
import './edit.sass';
bindSettings();
if (document.location.pathname.endsWith('/edit.html')) {
    var writepad = getElementById(HTMLTextAreaElement, 'writepad');
    var preview = getElementById(HTMLDivElement, 'preview');
    var title = getElementById(HTMLHeadingElement, 'nav-title');
    new Editor(writepad, preview, title);
}
else {
    bindWelcomeForm();
    fillExamples();
}
