import { getElementById } from './get-element-by-id';
import { getHashParam, removeHashParam } from './hash-param';
function getSlideFromHash() {
    var value = getHashParam('s');
    if (value !== null && /^\d+$/.exec(value))
        return Number(value);
    else
        return null;
}
export function bindControls() {
    var slides = getElementById(HTMLDivElement, 'slides');
    var controls = getElementById(HTMLDivElement, 'controls');
    var controlPrevious = getElementById(HTMLButtonElement, 'control-previous');
    var controlNext = getElementById(HTMLButtonElement, 'control-next');
    var controlHome = getElementById(HTMLButtonElement, 'control-home');
    var controlStatus = getElementById(HTMLSpanElement, 'control-current');
    var slidesCount = slides.children.length;
    var slidesCurrent = 0;
    controls.removeAttribute('hidden');
    function goToSlide(n) {
        if (n >= slidesCount)
            n = slidesCount - 1;
        if (n < 0)
            n = 0;
        slidesCurrent = n;
        slides.setAttribute('style', "top: " + n * -100 + "vh;");
        if (n === 0) {
            controlPrevious.setAttribute('disabled', 'true');
            controlNext.removeAttribute('disabled');
        }
        else if (n + 1 === slidesCount) {
            controlPrevious.removeAttribute('disabled');
            controlNext.setAttribute('disabled', 'true');
        }
        else {
            controlPrevious.removeAttribute('disabled');
            controlNext.removeAttribute('disabled');
        }
        controlStatus.innerText = n + 1 + "/" + slidesCount;
        if (getSlideFromHash() === null) {
            location.hash += (location.hash ? '&' : '#') + ("s=" + n);
        }
        else {
            location.hash = location.hash.replace(/([#&])s=(\d+)(&|$)/, function (_a, prefix, _b, suffix) { return prefix + "s=" + n + suffix; });
        }
    }
    function nextSlide() {
        goToSlide(slidesCurrent + 1);
    }
    function previousSlide() {
        goToSlide(slidesCurrent - 1);
    }
    window.addEventListener('hashchange', function () {
        var targetSlide = getSlideFromHash();
        if (targetSlide === null)
            return;
        goToSlide(targetSlide);
    });
    controlHome.addEventListener('click', function () {
        removeHashParam('url');
        removeHashParam('s');
        location.reload();
    });
    controlPrevious.addEventListener('click', previousSlide);
    controlNext.addEventListener('click', nextSlide);
    document.addEventListener('keydown', function (event) {
        switch (event.key) {
            case 'ArrowRight':
            case 'ArrowDown':
                nextSlide();
                break;
            case 'ArrowLeft':
            case 'ArrowUp':
                previousSlide();
                break;
        }
    });
    goToSlide(getSlideFromHash() || 0);
}
