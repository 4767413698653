import { parseMarkdown } from './dom-of-markdown';
import { load, save } from './editor-save';
function getOffset(slide) {
    var _a;
    if (!(slide instanceof HTMLElement))
        throw Error('slide is not HTMLElement');
    var startOffset = Number.parseInt((_a = slide.dataset.startOffset) !== null && _a !== void 0 ? _a : '');
    if (Number.isNaN(startOffset))
        return null;
    return startOffset;
}
var Preview = /** @class */ (function () {
    function Preview(container, title, onCursorOffsetChange) {
        this.container = container;
        this.onCursorOffsetChange = onCursorOffsetChange;
        this.focusedSlide = null;
        this.previewTitle = title;
    }
    Preview.prototype.focusSlideFromCursorOffset = function (cursorOffset) {
        var elementsWithOffset = Array.from(this.container.querySelectorAll('[data-start-offset]'));
        var match = elementsWithOffset
            .map(function (element) { return [element, getOffset(element)]; })
            .filter(nonNullOffset)
            .sort(byDescendingOffset)
            .find(function (_a) {
            var elementOffset = _a[1];
            return elementOffset <= cursorOffset;
        });
        if (match == null)
            return;
        var slide = match[0];
        this.focusSlide(slide);
        function nonNullOffset(elementWithOffset) {
            return elementWithOffset[1] != null;
        }
        function byDescendingOffset(_a, _b) {
            var offsetA = _a[1];
            var offsetB = _b[1];
            return offsetB - offsetA;
        }
    };
    Preview.prototype.setMarkdown = function (markdown, cursorOffset) {
        var _a = parseMarkdown(markdown), slides = _a.slides, title = _a.title;
        this.previewTitle.innerText = title;
        this.bindFindSlideInWritepad(slides);
        slides.setAttribute('id', 'slides');
        slides.classList.add('preview');
        this.container.innerText = '';
        this.container.appendChild(slides);
        this.focusSlideFromCursorOffset(cursorOffset);
    };
    Preview.prototype.focusSlide = function (slide) {
        if (this.focusedSlide != null)
            this.focusedSlide.classList.remove('slide-focus');
        slide.classList.add('slide-focus');
        slide.scrollIntoView({ block: 'center', behavior: 'smooth' });
        this.focusedSlide = slide;
    };
    Preview.prototype.bindFindSlideInWritepad = function (slides) {
        var _this = this;
        var _loop_1 = function (slide) {
            slide.addEventListener('click', function () {
                _this.focusSlide(slide);
                var cursorOffset = getOffset(slide);
                if (cursorOffset == null)
                    return;
                _this.onCursorOffsetChange(cursorOffset);
            });
        };
        for (var _i = 0, _a = Array.from(slides.children); _i < _a.length; _i++) {
            var slide = _a[_i];
            _loop_1(slide);
        }
    };
    return Preview;
}());
var Editor = /** @class */ (function () {
    function Editor(writepad, preview, title) {
        var _this = this;
        this.writepad = writepad;
        this.writepad.value = load();
        var onCursorOffsetChange = function (cursorOffset) {
            return _this.setCursorOffset(cursorOffset);
        };
        this.preview = new Preview(preview, title, onCursorOffsetChange);
        this.bindPreview();
    }
    Editor.prototype.updatePreview = function () {
        var markdown = this.writepad.value;
        var cursorOffset = this.writepad.selectionStart;
        save(markdown);
        this.preview.setMarkdown(markdown, cursorOffset);
    };
    Editor.prototype.movePreview = function () {
        var cursorOffset = this.writepad.selectionStart;
        this.preview.focusSlideFromCursorOffset(cursorOffset);
    };
    Editor.prototype.bindPreview = function () {
        var _this = this;
        this.updatePreview();
        this.writepad.addEventListener('input', function () { return _this.updatePreview(); });
        this.writepad.addEventListener('keydown', function () { return _this.movePreview(); });
        this.writepad.addEventListener('click', function () { return _this.movePreview(); });
    };
    Editor.prototype.setCursorOffset = function (cursorOffset) {
        this.writepad.focus();
        this.writepad.selectionEnd = cursorOffset;
        this.writepad.selectionStart = cursorOffset;
    };
    return Editor;
}());
export { Editor };
